import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
// slices
import mailReducer from './slices/mail';
import chatReducer from './slices/chat';
import blogReducer from './slices/blog';
import userReducer from './slices/user';
import productReducer from './slices/product';
import locationReducer from './slices/location';
import calendarReducer from './slices/calendar';
import kanbanReducer from './slices/kanban';
import tagReducer from './slices/tags';
import tagTypeReducer from './slices/tagtypes';
import bulkReducer from './slices/bulk';
import reqReducer from './slices/request';

// ----------------------------------------------------------------------

const rootPersistConfig = {
  key: 'root',
  storage,
  keyPrefix: 'redux-',
  whitelist: []
};

const productPersistConfig = {
  key: 'product',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['sortBy', 'checkout']
};
const locationPersistConfig = {
  key: 'location',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['sortBy', 'checkout']
};

const tagPersistConfig = {
  key: 'tag',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['sortBy', 'checkout']
};

const tagTypePersistConfig = {
  key: 'tagType',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['sortBy', 'checkout']
};
const bulkPersistConfig = {
  key: 'bulk',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['sortBy', 'checkout']
};
const userreqPersistConfig = {
  key: 'req',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['sortBy', 'checkout']
};

const rootReducer = combineReducers({
  mail: mailReducer,
  chat: chatReducer,
  blog: blogReducer,
  user: userReducer,
  calendar: calendarReducer,
  kanban: kanbanReducer,
  req: persistReducer(userreqPersistConfig, reqReducer),
  product: persistReducer(productPersistConfig, productReducer),
  location: persistReducer(locationPersistConfig, locationReducer),
  bulk: persistReducer(bulkPersistConfig, bulkReducer),
  tag: persistReducer(tagPersistConfig, tagReducer),
  tagType: persistReducer(tagTypePersistConfig, tagTypeReducer)
});

export { rootPersistConfig, rootReducer };
