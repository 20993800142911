import { useLocation } from 'react-router-dom';
import { createContext, useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';
// utils

import { isValidToken, setSession } from '../utils/jwt';
import { api } from '../api';

// ----------------------------------------------------------------------

const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null
};

const handlers = {
  INITIALIZE: (state, action) => {
    const { isAuthenticated, user } = action.payload;
    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user
    };
  },
  LOGIN: (state, action) => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user
    };
  },
  LOGOUT: (state) => ({
    ...state,
    isAuthenticated: false,
    user: null
  }),
  REGISTER: (state, action) => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user
    };
  }
};

const reducer = (state, action) => (handlers[action.type] ? handlers[action.type](state, action) : state);

const AuthContext = createContext({
  ...initialState,
  method: 'jwt',
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  register: () => Promise.resolve()
});

AuthProvider.propTypes = {
  children: PropTypes.node
};

const pathenums = 'admin';

function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { pathname } = useLocation();

  useEffect(() => {
    const initialize = async () => {
      try {
        const accessToken = pathname.includes(pathenums)
          ? window.localStorage.getItem('adminToken')
          : window.localStorage.getItem('accessToken');
        const pathee = pathname.includes(pathenums) ? 'adminToken' : 'accessToken';
        const path = pathname.includes(pathenums) ? '/corporate/auth/admin/verify-user' : '/corporate/auth/verify-user';
        console.log(accessToken, pathee);
        if (accessToken) {
          if (pathee === 'accessToken') {
            setSession(accessToken, 'accessToken');
          }
          if (pathee === 'adminToken') {
            setSession(accessToken, 'adminToken');
          }

          const response = await api.post(path, {
            token: accessToken
          });
          const user = response.data.data;

          const fulldata = {
            ...user,
            about: 'Praesent turpis. Phasellus viverra nulla ut metus varius laoreet. Phasellus tempus.',
            address: '90210 Broadway Blvd',
            city: 'San Francisco',
            country: 'United States',
            displayName: user.name,
            isPublic: true,
            phoneNumber: '+40 777666555',
            photoURL: '/static/mock-images/avatars/avatar_default.jpg',
            state: 'California',
            zipCode: '94116',
            role: pathname.includes(pathenums) ? 'admin' : 'user',
            authToken: accessToken
          };

          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated: true,
              user: fulldata
            }
          });
        } else {
          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated: false,
              user: null
            }
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: 'INITIALIZE',
          payload: {
            isAuthenticated: false,
            user: null
          }
        });
      }
    };

    initialize();
  }, []);

  const login = async (email, password) => {
    console.log('im hitting');
    const path = pathname.includes('admin') ? '/corporate/auth/admin/signin' : '/corporate/auth/signin';
    let response;
    if (pathname.includes('admin')) {
      response = await api.post(path, {
        email,
        password
      });
    } else {
      response = await api.post(path, {
        email,
        password
      });
    }

    const { user } = response.data.data;

    const { authToken } = response.data.data;
    const fulldata = {
      ...user,
      about: 'Praesent turpis. Phasellus viverra nulla ut metus varius laoreet. Phasellus tempus.',
      address: '90210 Broadway Blvd',
      city: 'San Francisco',
      country: 'United States',
      displayName: user?.name,
      isPublic: true,
      phoneNumber: '+40 777666555',
      photoURL: '/static/mock-images/avatars/avatar_default.jpg',
      state: 'California',
      zipCode: '94116',
      role: 'admin',
      authToken
    };
    if (pathname.includes(pathenums)) {
      setSession(authToken, 'admin');
    } else if (pathname.includes('corporate')) {
      setSession(authToken, 'corporate');
    }
    console.log(fulldata);

    dispatch({
      type: 'LOGIN',
      payload: {
        user: fulldata
      }
    });
  };

  const register = async (email, password, firstName, lastName) => {
    const response = await api.post('/api/account/register', {
      email,
      password,
      firstName,
      lastName
    });
    const { accessToken, user } = response.data;

    // window.localStorage.setItem('accessToken', accessToken);
    dispatch({
      type: 'REGISTER',
      payload: {
        user
      }
    });
  };

  const logout = async () => {
    setSession(false, pathname.includes(pathenums));
    dispatch({ type: 'LOGOUT' });
    window.location.reload();
  };

  const resetPassword = () => {};

  const updateProfile = () => {};

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'jwt',
        login,
        logout,
        register,
        resetPassword,
        updateProfile
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
